(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:moduleSetSelector
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <module-set-selector></module-set-selector>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('moduleSetSelector', moduleSetSelector);

  function moduleSetSelector() {
    return {
      restrict: 'EA',
      scope: {
        search: '=',
        moduleSetId: '='
      },
      bindToController: true,
      templateUrl: 'components/module-set-selector/module-set-selector-directive.tpl.html',
      replace: false,
      controllerAs: 'moduleSetSelector',
      controller: 'ModuleSetSelectorCtrl'
    };
  }
}());
